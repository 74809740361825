@font-face {
  font-family: Vazir;
  src: url("../../fonts/Vazir.eot");
  src: url("../../fonts/Vazir.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/Vazir.woff2") format("woff2"),
    url("../../fonts/Vazir.woff") format("woff"),
    url("../../fonts/Vazir.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Vazir;
  src: url("../../fonts/Vazir-Bold.eot");
  src: url("../../fonts/Vazir-Bold.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/Vazir-Bold.woff2") format("woff2"),
    url("../../fonts/Vazir-Bold.woff") format("woff"),
    url("../../fonts/Vazir-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: Vazir;
  src: url("../../fonts/Vazir-Black.eot");
  src: url("../../fonts/Vazir-Black.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/Vazir-Black.woff2") format("woff2"),
    url("../../fonts/Vazir-Black.woff") format("woff"),
    url("../../fonts/Vazir-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: Vazir;
  src: url("../../fonts/Vazir-Medium.eot");
  src: url("../../fonts/Vazir-Medium.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/Vazir-Medium.woff2") format("woff2"),
    url("../../fonts/Vazir-Medium.woff") format("woff"),
    url("../../fonts/Vazir-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Vazir;
  src: url("../../fonts/Vazir-Light.eot");
  src: url("../../fonts/Vazir-Light.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/Vazir-Light.woff2") format("woff2"),
    url("../../fonts/Vazir-Light.woff") format("woff"),
    url("../../fonts/Vazir-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Vazir;
  src: url("../../fonts/Vazir-Thin.eot");
  src: url("../../fonts/Vazir-Thin.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/Vazir-Thin.woff2") format("woff2"),
    url("../../fonts/Vazir-Thin.woff") format("woff"),
    url("../../fonts/Vazir-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}





$font-family-heading: 'Vazir', sans-serif;
$font-family-base: 'Vazir', sans-serif;
$font-size-base: .913rem;


// // LAYOUT VARS
// $topbar-height: 80px;
// $topbar-height-mobile: 70px;

// $sidebar-left-width: 120px;
// $sidebar-left-width-mobile: 90px;
// $sidebar-left-secondary-width: 220px;
// $sidebar-left-secondary-width-mobile: 190px;
// $side-clearance: 20px;

// GLOBAL COLORS
$facebook: rgb(55, 101, 201);
$google: rgb(236, 65, 44);
$twitter: rgb(3, 159, 245);
