@import "@swimlane/ngx-datatable/index.css";
@import "@swimlane/ngx-datatable/themes/material.css";
@import "@swimlane/ngx-datatable/assets/icons.css";
@import "toaster.scss";
@import "ngx-toastr/toastr.css";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
// @import "angular-calendar/css/angular-calendar.css";
// @import "quill/dist/quill.core.css";
// @import "quill/dist/quill.snow.css";
// @import "highlight.js/styles/github.css";
// @import "ladda/dist/ladda.min.css";
@import "../primeicons/primeicons.css";
@import "../iconsmind/iconsmind.css";

// @import 'themes/lite-blue';
@import "variables";
@import "themes/lite-purple";
@import "../vendor/bootstrap/bootstrap.scss";
@import "bootstrap-rtl.scss";
@import "globals/globals";

.p-calendar .p-inputtext {
  padding: 0.55625rem;
}

.p-component {
  font-family: $font-family-base !important;
}
.p-sidebar {
  background: rgba(63, 67, 67, 0.9411764706) !important;
}

.edit {
  font-size: 18px !important;
  color: rgb(185, 223, 17) !important;
}
.delete {
  font-size: 18px !important;
  color: rgb(187, 87, 62) !important;
}

.mat-text-warn {
  color: red !important;
}
.w-fit-content {
  width: fit-content !important;
}
.empty-row {
  text-align: right !important;
}
.vertical {
  border-left: 2px solid rgb(91, 91, 94);
  height: 21px !important;
}
.dp-material input[type="text"] {
  border-radius: 5px;
  text-align: right;
  direction: rtl;
  display: block;
  height: calc(2.5em + 19px + 0px) !important;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: transparent;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.dp-material .dp-picker-input {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100% !important;
  outline: none;
  margin: 4px;
}

.dp-day-calendar-container {
  z-index: 10530;
  position: fixed;
}
// .dp-material{
//   position: fixed;
//   left: 120px !important;
// }
.dp-time-select-controls {
  position: fixed;
}
.cursor-pointer {
  cursor: pointer;
}
.datepicker-outer-container {
  z-index: 100;
  position: relative !important;
}

/* You can add global styles to this file, and also import other style files */
.datatable-footer {
  direction: ltr;
}

.dp-material input[type="text"] {
  border-radius: 5px;
  text-align: right;
  direction: rtl;
  display: block;
  height: calc(2.5em + 19px + 0px) !important;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: transparent;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.dp-material .dp-picker-input {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100% !important;
  outline: none;
  margin: 4px;
}

.img-card {
  object-fit: contain;

  width: 100%;
  height: 100px;
}

.gif-fluid {
  background-color: white;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.th-list div {
  color: black !important;
  font-size: 18px !important;
}

.custom-btn {
  width: 130px;
  height: 40px;
  padding: 10px 25px;
  border: 2px solid #000;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
}

.btn-6 {
  background: #663399;
  border-radius: 12px;
  color: #fff;
  line-height: 42px;
  font-weight: bold;
  padding: 0;
  border: none;
}
.btn-6 span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.btn-6:before,
.btn-6:after {
  position: absolute;
  content: "";
  height: 0%;
  width: 2px;
  background: #000;
}
.btn-6:before {
  right: 0;
  top: 0;
  transition: all 500ms ease;
}
.btn-6:after {
  left: 0;
  bottom: 0;
  transition: all 500ms ease;
}
.btn-6:hover {
  color: #000;
  background: transparent;
}
.btn-6:hover:before {
  transition: all 500ms ease;
  height: 100%;
}
.text-secondary {
  color: #6c757d !important;
}
.btn-6:hover:after {
  transition: all 500ms ease;
  height: 100%;
}
.btn-6 span:before,
.btn-6 span:after {
  position: absolute;
  content: "";
  background: #000;
}
.btn-6 span:before {
  left: 0;
  top: 0;
  width: 0%;
  height: 2px;
  transition: all 500ms ease;
}
.btn-6 span:after {
  right: 0;
  bottom: 0;
  width: 0%;
  height: 2px;
  transition: all 500ms ease;
}
.btn-6 span:hover:before {
  width: 100%;
}
.btn-6 span:hover:after {
  width: 100%;
}

.btn-create {
  display: inline-block;
}

.badge-rounder {
  border-radius: 0.25rem !important;
}
.card-body {
  padding: 0.8rem !important;
}
.bg-light-dark {
  background-color: rgba(63, 67, 67, 0.9411764706);
}
.bg-gray {
  background-color: rgb(100, 100, 100);
}

.bg--primary {
  background: linear-gradient(
    169deg,
    rgba(36, 213, 111, 1) 0%,
    rgba(102, 226, 174, 1) 48%,
    rgba(109, 190, 172, 1) 89%
  );
}

/*
*
*
*
accordion
*
*
*
*/

.accordion {
  --bs-accordion-color: #212529;
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: #dee2e6;
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 0.375rem;
  --bs-accordion-inner-border-radius: calc(0.375rem - (1px));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #212529;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230a58ca'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #0a58ca;
  --bs-accordion-active-bg: #cfe2ff;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
    var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-right: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid
    var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button,
.accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

[data-bs-theme="dark"] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

/*
*
*
*
Buttons
*
*
*
*/
.border-raduis-5 {
  border-radius: 5px;
  border: 3px;
  border-style: dashed;
  border-color: aquamarine;
}
.Icon_size {
  font-size: 3.5rem !important;
}
.radius {
  border-radius: 6px;
}
.text-bigger {
  font-size: 15px;
  font-weight: bold;
}

.btn-details {
  color: #e9e1e1 !important;
  background-color: #797576f4;
  transition: all 0.15s ease-in-out;
}
.btn-details:hover {
  background-color: #605d5e;
  transition: all 0.15s ease-in-out;
}
.btn-download {
  color: rgb(71, 64, 79) !important;
  background-color: #e5dbdef4;
  transition: all 0.15s ease-in-out;
}
.btn-download:hover {
  background-color: #797576f4;
  transition: all 0.15s ease-in-out;
}
.btn-download i {
  margin: 0;
  transition: all 0.15s ease-in-out;
}
.btn-download:hover i {
  margin-top: 1.75px !important;
  transition: all 0.15s ease-in-out;
}

.btn-upload {
  color: rgb(71, 64, 79) !important;
  background-color: #e5dbdef4;
  transition: all 0.15s ease-in-out;
}
.btn-upload:hover {
  background-color: #797576f4;
  transition: all 0.15s ease-in-out;
}
.btn-upload i {
  margin: 0;
  transition: all 0.15s ease-in-out;
}
.btn-upload:hover i {
  margin-bottom: 1.75px !important;
  transition: all 0.15s ease-in-out;
}

.btn-delete {
  color: #e9e1e1 !important;
  background-color: #a70000b7 !important;
  transition: all 0.15s ease-in-out;
}
.btn-delete:hover {
  background-color: #5c0000b7 !important;
  transition: all 0.15s ease-in-out;
}

.py-btns {
  padding: 0.45rem 1.3rem;
}

.btn-save {
  color: white !important;
  background-color: rgb(11, 117, 11) !important;
}
.btn-save:hover {
  background-color: rgb(7, 71, 7) !important;
  transition: all 0.15s ease-in-out;
}

.btn-add {
  color: white !important;
  background-color: #2d0440;
}
.btn-add:hover {
  background-color: #14031c;
  transition: all 0.15s ease-in-out;
}

.window-z-index {
  z-index: 2001 !important;
}

.text_gray {
  color: gray !important;
}

.bg_gray {
  background-color: #797576f4;
}

.p-organizationchart-node-content {
  border: 3px solid #dee2e6 !important;
}
.p-organizationchart-line-left {
  border-right: 3px solid #dee2e6 !important;
  border-right-color: rgb(222, 226, 230);
  border-color: #dee2e6;
}
// .p-organizationchart-line-right {
//   border-right: 3px solid #dee2e6  !important;
//     border-right-color: rgb(222, 226, 230);
//   border-color: #dee2e6;
// }
.p-organizationchart-line-top {
  border-top: 3px solid #dee2e6 !important;
  border-color: #dee2e6;
}
.p-tree .p-treenode-children {
  padding: 0 2rem 0 0 !important;
}

.logNode {
  .p-treenode-leaf {
    display: none !important;
  }
  .p-treenode {
    border-bottom: 1px solid #ccc;
  }
  .p-treenode-children {
    .p-treenode-leaf {
      display: block !important;
    }
  }
}

.p-dropdown {
  background: #eff8fb !important;
  border-radius: 10px !important;
  display: flex !important;
}
.p-dropdown-label {
  font-size: 0.82rem !important;
  padding: 12px !important;
  font-family: "Vazir" !important;
}

.p-dropdown-items-wrapper::-webkit-scrollbar {
  width: 6px;
  margin-top: 2px;
  background: #fff;
  border-radius: 0 0 0 6px;
}
.p-dropdown-items-wrapper::-webkit-scrollbar-thumb {
  background: #5e6a69;
  border-radius: 10px;
}
.p-dropdown-items-wrapper::-webkit-scrollbar-track {
  border-radius: 10px;
}
.p-dropdown-item {
  font-size: 0.82rem !important;
}

.w-auto {
  width: auto;
}
.p-tree-toggler {
  margin-left: 7px !important;
}
.p-treenode-leaf {
  border-bottom: none !important;
}
.f-vazir {
  font-family: "Vazir", sans-serif !important;
}
.p-organizationchart {
  scale: 0.8;
}
.dropDown-scroll::-webkit-scrollbar {
  width: 6px;
  margin-top: 2px;
  background: #fff;
  border-radius: 0 0 0 6px;
}
.dropDown-scroll::-webkit-scrollbar-thumb {
  background: #5e6a69;
  border-radius: 10px;
}
.dropDown-scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}
.date-picker:focus {
  background: #fff !important;
}
.p-button-rounded {
  height: 1rem !important;
}
.p-button-icon-only {
  width: 1rem !important;
  padding: 0.75rem !important;
  .pi {
    font-size: 0.62rem !important;
    margin-top: 1.5px;
  }
}
.remove-tag {
  color: #e9e1e1 !important;
  background-color: #a70000b7 !important;
  transition: all 0.15s ease-in-out;
}
.edite-tag {
  color: #e9e1e1 !important;
  background-color: #4b92db !important;
  transition: all 0.15s ease-in-out;
}
.p-multiselect-chip {
  width: 100%;
}
.p-tabview-nav-content {
  font-size: 0.88rem;
  .p-tabview-nav-link {
    &:focus {
      box-shadow: none !important;
    }
    color: #325691 !important;
    .p-tabview-title {
      margin: 0 8px 0 0;
    }
  }
}
.p-dialog-header {
  padding: 0.5rem !important;
  background: #e1dbdb !important;
}
.p-dialog-content {
  padding: 0.5rem 1.5rem 2rem 1.5rem !important;
}
.p-dialog {
  max-height: 96% !important;
}
.p-contextmenu
  .p-menuitem.p-menuitem-active
  > .p-menuitem-link
  .p-menuitem-icon,
.p-contextmenu
  .p-menuitem.p-menuitem-active
  > .p-menuitem-link
  .p-submenu-icon {
  color: #1e2637;
}
.p-contextmenu
  .p-menuitem.p-menuitem-active
  > .p-menuitem-link
  .p-menuitem-text {
  color: #1e2637;
}
.p-menuitem-icon {
  margin-left: 0.5rem;
}
.p-datepicker {
  direction: ltr !important;
}
.p-tooltip-text {
  font-size: 0.75rem !important;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  padding-right: 1.75rem;
  margin-right: 0;
}
.p-float-label label {
  right: 4%;
}
.p-multiselect-panel .p-multiselect-header {
  padding: 0.5rem 0;
}
.p-multiselect .p-multiselect-label {
  padding: 0.56rem 0.59rem;
}
.p-multiselect-filter-container {
  margin: 0 5px;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token {
  font-size: 0.76rem;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  font-size: 0.85rem;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
  margin-right: 0;
  margin-left: 0.5rem;
}
.p-multiselect.p-multiselect-chip
  .p-multiselect-token
  .p-multiselect-token-icon {
  margin-right: 0.5rem;
}
.p-multiselect {
  border-radius: 9px;
}
.p-multiselect-panel
  .p-multiselect-header
  .p-multiselect-filter-container
  .p-inputtext {
  padding: 0.15rem 0.75rem;
  font-size: 0.85rem;
}
.p-picklist-item {
  margin: 0.75rem !important;
  background-color: #f1efef !important;
  padding: 0.5rem 1rem !important;
  border-radius: 28px;
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s !important;
  &:hover {
    background-color: #3a93a7 !important;
    color: #fff !important;
  }
}

// .p-inputnumber-buttons-vertical .p-button.p-inputnumber-button-up {
//   padding: 0.2rem 0 !important;
//   width: 100% !important;
// }
// .p-inputnumber-buttons-vertical .p-button.p-inputnumber-button-down {
//   padding: 0.2rem 0 !important;
//   width: 100% !important;
// }
// .p-inputnumber-buttons-vertical .p-inputnumber-input {
//   padding: 0.25rem 0 !important;
//   font-size: 0.83rem;
//   max-width: 33px;
// }
