h1,
h2,
h3,
h4,
h5,
h6,
.card-title,
.text-title {
  color: $heading;
}

@for $i from 10 through 78 {
  .text-#{$i} {
    font-size: #{$i}px;
  }
}
@for $i from 300 through 900 {
  .font-weight-#{$i} {
    font-weight: $i;
  }
}

.text-small {
  font-size: 0.82rem;
}
