.layout-sidebar-compact {
    .main-header {
        position: absolute !important;
        width: 100%;
        left: 0;
        box-shadow: none;
        // box-shadow: 0 1px 15px rgba(0,0,0,.04), 0 1px 6px rgba(0,0,0,.04);
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        background: transparent;
        z-index: 100;
        transition: all .24s ease-in-out;
        .logo {
            display: none;
        }
    }
}
.main-header {
    position: fixed;
    height: $topbar-height;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    background: $background;
    z-index: 100;
    .menu-toggle {
        width: 90px;
        display: flex;
        flex-direction: column;
        align-items: center;       
        cursor: pointer;
        div {
            width: 24px;
            height: 1px;
            background: $foreground;
            margin: 3px 0;
        }
    }

    .search-bar {
        display: flex;
        align-items: center;
        justify-content: left;
        background: $gray-100;
        border: 1px solid $gray-200;
        border-radius: 20px;
        position: relative;
        width: 230px;
        height: 40px;
        input {
            background: transparent;
            border: 0;
            color: #212121;
            font-size: .8rem;
            line-height: 2;
            height: 100%;
            outline: initial!important;
            padding: .5rem 1rem;
            width: calc(100% - 32px);
        }
        .search-icon {
            font-size: 18px;
            width: 24px;
            display: inline-block;
        }
    }

    .logo {
        width: $sidebar-left-width;
        img {
            width: 60px;
            height: 60px;
            margin: 0 auto;
            display: block;
        }
    }
    .header-icon {
        font-size: 19px;
        cursor: pointer;
        height: 36px;
        width: 36px;
        line-height: 36px;
        display: inline-block;
        text-align: center;
        border-radius: 8px;
        margin: 0 2px;
        &:hover {
            background: $gray-100;
        }
        &.dropdown-toggle {
            &:after { display: none; }
        }
    }
    .header-part-right {
        display: flex;
        align-items: center;
        .user {
            margin-right: 2rem;
            img {
                width: 36px;
                height: 36px;
                border-radius: 50%;
            }
        }
    }
    .notification-dropdown {
        padding: 0;
        max-height: 260px;
        cursor: pointer;
        .dropdown-item {
            display: flex;
            align-items: center;
            padding: 0;
            height: 72px;
            border-bottom: 1px solid $gray-300;
            .notification-icon {
                background: $gray-200;
                height: 100%;
                width: 44px;
                display: flex;
                align-items: center;
                justify-content: center;
                i {
                    font-size: 18px;
                }
            }
            .notification-details {
                padding: .25rem .75rem;
            }
            &:active {
                color: inherit;
                background: inherit;
            }
        }
        
    }
}
@media (max-width: 1024px) {
    .main-header {
        .search-bar {
            width: 180px;
            display: none;
        }
        .menu-toggle {
            width: 24px; 
            margin-right: 36px;      
        }
        .header-part-right {
            .user {
                margin-right: 1rem;
            }
        }
    }
}

@media (max-width: 767px) {
    .main-header {
        .search-bar {
            display: none;
        }
        .menu-toggle {
            width: 24px;       
        }
    }
}

@media (max-width: 576px) {
    .main-header {
        height: $topbar-height-mobile;
        padding: 0 1.5rem;
        .logo {
            width: 60px;
        }
        
        .menu-toggle {
            width: 60px;
            margin-right: 0;
        }
        .header-part-right {
            .user {
                margin-right: 0;
                padding-right: 0;
            }
        }
    }
    .notification-dropdown {
        left: -180px !important;
    }

}

@media (max-width: 360px) {
    .main-header {
        .menu-toggle {
            margin: 0;
        }
    }

}